import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ "question", "buttonyes", "buttonno" ]
    initialize() {
        console.log("initializing...")
        this.qst_index = 1
        this.showNext()
        // this.questions = new Map([
        //     [1, "Это связано с вашим имуществом?"],
        //     [2, "Виновный, причиняя вред Вашему имуществу, преследовал при этом цель получить прибыль?"],
        //     [3, "Виновный получил имущество бесплатно или заплатил слишком мало ИЛИ У виновного была личная заинтересованность или он хотел отомстить?"],
        //     [4, "Данные действия были сопряжены с преднамеренным неисполнением договорных обязательств в сфере Вашей предпринимательской дейятельности?"],
        //     [5, "Виновный на данный момент уже требовал передать имущество или совершить действия имущественного характера?"],
        //     [6, "Виновный уже угрожал Вам применением насилия, либо грозился повредить/уничтожить имущество, либораспространить сведения, которые могут причинить вред Вам или Вашим близким?"]
        // ]);
    }

    yes(){
        if ((this.qst_index == 4) || (this.qst_index == 6)){

            let laws = (this.qst_index == 4)? "ч.5-7 ст.159 УК РФ" : "ст.163 УК РФ"
            this.questionTarget.textContent = "Преступление, с наибольшей вероятностью, было совершено по статье " + laws +
                ". Вам нужно обратиться с заявлением о преступлении в отделение полиции по адресу, где было совершено преступление. " +
                "Список адресов для г.Ростова-на-Дону представлен ниже."
            this.buttonyesTarget.hidden = true
            this.buttonnoTarget.hidden = true
        }
        else {
            this.qst_index ++
            this.showNext()

        }

    }
    no(){
        if (this.qst_index == 3){
            this.qst_index = 5
        }
        else{
            this.qst_index = 0
        }
        this.showNext()
    };


    showNext() {
        if ( this.qst_index != 0) {
            let q = ""
            switch (this.qst_index){
                case 1:
                    q = "Это связано с Вашим имуществом?"
                    break;
                case 2:
                    q = "Виновный, причиняя вред Вашему имуществу, преследовал при этом цель получить прибыль?"
                    break;
                case 3:
                    q = "Виновный получил имущество бесплатно или заплатил слишком мало \nИЛИ\nУ виновного была личная заинтересованность или он хотел отомстить?"
                    break;
                case 4:
                    q = "Данные действия были сопряжены с преднамеренным неисполнением договорных обязательств в сфере Вашей предпринимательской деятельности?"
                    break;
                case 5:
                    q = "Виновный на данный момент уже требовал передать имущество или совершить действия имущественного характера?"
                    break;
                case 6:
                    q = "Виновный уже угрожал Вам применением насилия, либо грозился повредить/уничтожить имущество, либо распространить сведения, которые могут причинить вред Вам или Вашим близким?"
                    break;
            }
            this.questionTarget.textContent = q
        }
        else {
            this.questionTarget.textContent = "Рекомендуем заполнить заявление о преступлении и обратиться в отделение полиции по месту совершения преступления."
            this.buttonyesTarget.hidden = true;
            this.buttonnoTarget.hidden = true
        }

    }

}